const VariableCreate = () => import('../../pages/variable/Create.vue');

export default {
    name: 'variable.create',
    path: 'create',
    component: VariableCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Variable',
    },
};
