const UserNewsIndex = () => import('../../pages/news/ListNews.vue');

export default {
    name: 'news.listNews',
    path: 'list',
    component: UserNewsIndex,
    meta: {
        breadcrumb: 'list',
        title: 'News',
    },
};
