const privacy = () => import('../../pages/auth/Privacy.vue');

export default {
    name: 'docs.privacy',
    path: 'privacy',
    component: privacy,
    meta: {
        guestGuard: true,
        breadcrumb: 'privacy',
        title: 'privacy',
    },
};
