const NewsIndex = () => import('../../pages/news/Index.vue');

export default {
    name: 'news.index',
    path: '',
    component: NewsIndex,
    meta: {
        breadcrumb: 'index',
        title: 'News',
    },
};
