const NewsShow = () => import('../../pages/news/Show.vue');

export default {
    name: 'news.show',
    path: ':news',
    component: NewsShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show News',
    },
};
