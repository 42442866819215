const Approves = () => import('../../components/trafficLight/Approves.vue');

export default {
    name: 'liquidation.approves',
    path: ':liquidation/approves/:period',
    component: Approves,
    meta: {
        breadcrumb: 'approves',
        title: 'Approves Liquidation',
    },
};
