const resetpassaword = () => import('../pages/auth/ActiveForm.vue');

export default {
    name: 'auth.resetpassaword',
    path: '/resetPassaword',
    component: resetpassaword,
    meta: {
        title: 'Reset Passaword',
        guestGuard: true,
    },
};
