const BookMagazineEdit = () => import('../../pages/bookMagazine/Edit.vue');

export default {
    name: 'bookMagazine.edit',
    path: ':bookMagazine/edit',
    component: BookMagazineEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Book Magazine',
    },
};
