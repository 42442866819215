const BannerImagesShow = () => import('../../pages/bannerImages/Show.vue');

export default {
    name: 'bannerImages.show',
    path: ':bannerImages',
    component: BannerImagesShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Banner Images',
    },
};
