<template>
    <div class="wrapper-stepper">
        <div class="stepper">
            <div class="stepper-progress">
                <div class="stepper-progress-bar" :style="'width:' + stepperProgress"/>
            </div>

            <div class="stepper-item" :class="{ 'current': step === item,
             'success': step > item }" v-for="item in 4" :key="item">
                <div class="stepper-item-counter">
                    <img class="icon-success" src="https://www.seekpng.com/png/full/1-10353_check-mark-green-png-green-check-mark-svg.png" alt="">
                    <span class="number">{{ item }}</span>
                </div>
                <span class="stepper-item-title">Paso {{ item }}</span>
            </div>
        </div>

        <div class="stepper-content" v-for="item in 4" :key="item">
            <div class="stepper-pane" v-if="step === item">
                {{ i18n('You are in step') }} <span class="tx-green-1">
                {{ item }}
            </span>
                <div v-if="item === 1">
                    <CreateProgram/>
                </div>
                <div v-if="item === 2">
                    <div v-if="showVariableForm">
                        <CreateVariable/>
                    </div>
                </div>
                <div v-if="item === 3">
                    <div v-if="showRuleForm">
                        <CreateRule/>
                    </div>
                </div>
                <div v-if="item === 4">
                    <div v-if="showIndex">
                        <Index/>
                    </div>
                </div>
            </div>
        </div>

        <div class="controls">
            <button class="btn" @click="backStep(step)" :disabled="step === 1">
                {{ i18n('Back') }}
            </button>
            <button class="btn btn--green-1" @click="nextStep(step)"
                    :disabled="step === 4">
                {{ i18n('Next') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import Index from '@enso-ui/data-import/src/bulma/pages/dataImport/Index.vue';
import CreateProgram from '../../pages/programs/Create.vue';
import CreateVariable from '../../pages/variable/Create.vue';
import CreateRule from '../../pages/rule/Create.vue';

export default {
    name: 'Wizard',
    components: {
        CreateProgram, CreateVariable, CreateRule, Index,
    },
    inject: ['errorHandler', 'route', 'i18n', 'toastr', 'route'],
    data: () => ({
        showVariableForm: false,
        showRuleForm: false,
        showIndex: false,
        ready: false,
        step: 1,
    }),
    computed: {
        ...mapGetters('preferences', ['lang']),
        stepperProgress() {
            return `${(100 / 3) * (this.step - 1)}%`;
        },
    },
    methods: {
        ...mapMutations('layout', ['showFooter', 'hideFooter']),
        async nextStep(step) {
            if (step === 1) {
                this.step += 1;
                await this.$router.push('/variable/create');
                this.showVariableForm = true;
            } else if (step === 2) {
                this.step += 1;
                await this.$router.push('/rule/create');
                this.showRuleForm = true;
                this.showVariableForm = false;
            } else if (step === 3) {
                this.step += 1;
                await this.$router.push('/import');
                this.showIndex = true;
                this.showRuleForm = false;
            } else {
                this.step += 1;
            }
        },
        async backStep(step) {
            if (step === 3) {
                this.step -= 1;
                await this.$router.push('/variable/create');
                this.showVariableForm = true;
                this.showRuleForm = false;
            } else if (step === 4) {
                this.step -= 1;
                await this.$router.push('/rule/create');
                this.showRuleForm = true;
                this.showIndex = false;
            } else {
                this.step -= 1;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

$default: #C5C5C5;
$green-1: #75CC65;
$transiton: all 500ms ease;

.tx-green-1 {
    color: $green-1;
    font-weight: 600;
}
.stepper-item-title {
    position: unset !important;
}

.wrapper-stepper {
    background-color: #fff;
    padding: 20px; /* Reducido el espacio interior */
    border-radius: 16px; /* Reducido el radio del borde */
    box-shadow: 0 4px 6px rgba($color: #000000, $alpha: 0.09); /* Reducido el sombreado */
    width: 100%; /* Ancho completo */
    max-width: 80%;
    margin: 0 auto;
}

.stepper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 0;
    margin-bottom: 24px;

    &-progress {
        position: absolute;
        background-color: $default;
        height: 2px;
        z-index: -1;
        left: 0;
        right: 0;
        margin: 0 auto;

        &-bar {
            position: absolute;
            left: 0;
            height: 100%;
            width: 0%;
            background-color: $green-1;
            transition: $transiton;
        }
    }
}

.stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $default;
    transition: $transiton;
    margin: 1rem;

    &-counter {
        height: 68px;
        width: 68px;
        display: grid;
        place-items: center;
        background-color: #fff;
        border-radius: 100%;
        border: 2px solid $default;
        position: relative;

        .icon-success {
            position: absolute;
            opacity: 0;
            transform: scale(0);
            width: 24px;
            transition: $transiton;
        }

        .number {
            font-size: 22px;
            transition: $transiton;
        }
    }

    &-title {
        position: absolute;
        font-size: 14px;
        bottom: -24px;
    }
}
.number {
    align-items: center;
    border-radius: 9999px;
    display: inline-flex;
    font-size: 1.25rem;
    height: 2em;
    justify-content: center;
    background-color: transparent;
    margin-right: 0;
}

.stepper-item.success {
    .stepper-item-counter {
        border-color: $green-1;
        background-color: #c8ebc1;
        color: #fff;
        font-weight: 600;

        .icon-success {
            opacity: 1;
            transform: scale(1);
        }

        .number {
            opacity: 0;
            transform: scale(0);
        }
    }

    .stepper-item-title {
        color: $green-1;
        position: unset;
    }
}

.stepper-item.current {
    .stepper-item-counter {
        border-color: $green-1;
        background-color: $green-1;
        color: #fff;
        font-weight: 600;
    }

    .stepper-item-title {
        color: #818181;
        position: unset;
    }
}

.stepper-pane {
    color: #333;
    text-align: center;
    padding: 20px 10px; /* Reducido el espacio interior y el padding */
    box-shadow: 0 4px 6px rgba($color: #000000, $alpha: 0.09);
    margin: 20px 0; /* Reducido el margen vertical */
}

.controls {
    display: flex;
    justify-content: space-between; /* Mayor espacio entre los botones */
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    border: 1px solid;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    transition: all 150ms;
    border-radius: 4px;
    width: fit-content;
    font-size: 0.75rem;
    color: #333;
    background-color: #f0f0f0;
    border-color: #f0f0f0;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &--green-1 {
        background-color: $green-1;
        border-color: $green-1;
        color: #fff;
        margin-left: auto;
    }
}
</style>
