const BannerShow = () => import('../../pages/banner/Show.vue');

export default {
    name: 'banner.show',
    path: ':banner',
    component: BannerShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Banner',
    },
};
