const NewsImagesEdit = () => import('../../pages/newsImages/Edit.vue');

export default {
    name: 'newsImages.edit',
    path: ':newsImages/edit',
    component: NewsImagesEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit News Images',
    },
};
