const AlertsEdit = () => import('../../pages/alerts/Edit.vue');

export default {
    name: 'alerts.edit',
    path: ':alerts/edit',
    component: AlertsEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Alerts',
    },
};
