const LiquidationIndex = () => import('../../pages/liquidation/Index.vue');

export default {
    name: 'liquidation.index',
    path: '',
    component: LiquidationIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Liquidations',
    },
};
