const BookMagazineImagesCreate = () => import('../../pages/bookMagazineImages/Create.vue');

export default {
    name: 'bookMagazineImages.create',
    path: 'create',
    component: BookMagazineImagesCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Book Magazine Images',
    },
};
