<template>
    <div class="columns is-centered">
        <div class="column is-three-quarters-desktop is-full-touch">
            <enso-form class="box form-box has-background-light raises-on-hover" ref="ruleForm">
                <template #gives_points="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toggleGivesPoints"/>
                </template>
                <template #by_rank="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toogleByRank"/>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { EnsoForm, FormField } from '@enso-ui/bulma';

export default {
    name: 'Create',

    components: { EnsoForm, FormField },

    data() {
        return {
            ready: false,
        };
    },
    computed: {
        form() {
            return this.ready
                ? this.$refs.ruleForm
                : {};
        },
    },
    mounted() {
        this.ready = true;
    },
    methods: {
        toggleGivesPoints(givesPoints) {
            if (!givesPoints) {
                console.error('gives Points object is null or undefined');
                return;
            }
            if (this.$refs.ruleForm && this.$refs.ruleForm.field) {
                if (givesPoints.value !== null && givesPoints.value === 1) {
                    this.$refs.ruleForm.field('by_rank').meta.hidden = false;
                    this.$refs.ruleForm.field('points').meta.hidden = false;
                } else if (givesPoints.value !== null && givesPoints.value === 0) {
                    this.$refs.ruleForm.field('by_rank').meta.hidden = true;
                    this.$refs.ruleForm.field('points').meta.hidden = true;
                }
            } else {
                console.error('ruleForm or ruleForm.field is not defined');
            }
        },
        toogleByRank(byRank) {
            if (!byRank) {
                console.error('byRank object is null or undefined');
                return;
            }
            if (this.$refs.ruleForm && this.$refs.ruleForm.field) {
                if (byRank.value !== null && byRank.value === 1) {
                    this.$refs.ruleForm.field('initial_value').meta.hidden = false;
                    this.$refs.ruleForm.field('final_value').meta.hidden = false;
                    this.$refs.ruleForm.field('value').meta.hidden = true;
                } else if (byRank.value !== null && byRank.value === 0) {
                    this.$refs.ruleForm.field('value').meta.hidden = false;
                    this.$refs.ruleForm.field('initial_value').meta.hidden = true;
                    this.$refs.ruleForm.field('final_value').meta.hidden = true;
                }
            } else {
                console.error('ruleForm or ruleForm.field is not defined');
            }
        },
    },

};
</script>

<style lang="scss">
</style>
