const conditions = () => import('../../pages/auth/Conditions.vue');

export default {
    name: 'docs.conditions',
    path: 'conditions',
    component: conditions,
    meta: {
        guestGuard: true,
        breadcrumb: 'conditions',
        title: 'Service Conditions',
    },
};
