import routeImporter from '@enso-ui/ui/src/modules/importers/routeImporter';

const routes = routeImporter(require.context('./user', false, /.*\.js$/));
const AddPassword = () => import('../pages/auth/AddPassword.vue');

export default {
    name: 'addpasword',
    path: '/addpasword',
    component: AddPassword,
    meta: {
        breadcrumb: 'addpasword',
        title: 'Add Pasword',
    },
    children: routes,
};
