const BannerIndex = () => import('../../pages/banner/Index.vue');

export default {
    name: 'banner.index',
    path: '',
    component: BannerIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Banners',
    },
};
