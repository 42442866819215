const BookMagazineImagesEdit = () => import('../../pages/bookMagazineImages/Edit.vue');

export default {
    name: 'bookMagazineImages.edit',
    path: ':bookMagazineImages/edit',
    component: BookMagazineImagesEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Book Magazine Images',
    },
};
