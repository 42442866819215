import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faTachometerAlt, faMoneyBill, faAtlas, faCreditCard, faHome,
    faDollarSign, faPlus, faMinus, faMoneyCheckAlt, faWallet,
    faUser, faCommentDots, faPlaneDeparture, faImages,
    faAddressBook, faMonument, faNewspaper, faStar, faCompass,
    faLaptopMedical, faUpload, faTicketAlt, faArrowLeft, faTrashAlt,
    faEye, faCheck, faMap, faCloud, faArrowDown, faPlane, faComment,
    faMedal, faHandHoldingHeart, faPen, faIdBadge, faHandHolding,
    faHourglassHalf, faBirthdayCake, faMoneyBillWave, faBookOpen,
    faSuperscript, faPenNib, faBookmark, faClone, faList, faHatWizard,
    faChevronLeft, faChevronRight, faGift, faUserClock,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faTachometerAlt,
    faMap,
    faCloud,
    faArrowDown,
    faPlane,
    faStar,
    faAtlas,
    faMonument,
    faNewspaper,
    faMoneyBill,
    faCreditCard,
    faHome,
    faWallet,
    faDollarSign,
    faPlus,
    faMinus,
    faMoneyCheckAlt,
    faUser,
    faCommentDots,
    faPlaneDeparture,
    faImages,
    faAddressBook,
    faCompass,
    faLaptopMedical,
    faUpload,
    faArrowLeft,
    faTicketAlt,
    faTrashAlt,
    faEye,
    faCheck,
    faTicketAlt,
    faArrowLeft,
    faComment,
    faMedal,
    faHandHoldingHeart,
    faIdBadge,
    faHandHolding,
    faHourglassHalf,
    faPen,
    faBirthdayCake,
    faMoneyBillWave,
    faBookOpen,
    faSuperscript,
    faPenNib,
    faBookmark,
    faClone,
    faList,
    faHatWizard,
    faChevronLeft,
    faChevronRight,
    faGift,
    faUserClock,
);
