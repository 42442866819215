const BannerCreate = () => import('../../pages/banner/Create.vue');

export default {
    name: 'banner.create',
    path: 'create',
    component: BannerCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Banner',
    },
};
