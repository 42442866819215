const UserBannerIndex = () => import('../../pages/banner/UserIndex.vue');

export default {
    name: 'banner.userIndex',
    path: 'user',
    component: UserBannerIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Banner',
    },
};
