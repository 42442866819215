const AlertsShow = () => import('../../pages/alerts/Show.vue');

export default {
    name: 'alerts.show',
    path: ':alerts',
    component: AlertsShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Alerts',
    },
};
