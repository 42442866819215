const WeatherEdit = () => import('../../pages/weather/Edit.vue');

export default {
    name: 'weather.edit',
    path: ':weather/edit',
    component: WeatherEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Weather',
    },
};
