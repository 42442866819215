const UserPostsIndex = () => import('../../pages/posts/UserPostsIndex.vue');

export default {
    name: 'posts.userPostsIndex',
    path: 'user',
    component: UserPostsIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Posts',
    },
};
