const NewsEdit = () => import('../../pages/news/Edit.vue');

export default {
    name: 'news.edit',
    path: ':news/edit',
    component: NewsEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit News',
    },
};
