const UserBookMagazineIndex = () => import('../../pages/bookMagazine/UserIndex.vue');

export default {
    name: 'bookMagazine.userIndex',
    path: 'user',
    component: UserBookMagazineIndex,
    meta: {
        breadcrumb: 'index',
        title: 'BookMagazine',
    },
};
