const PointShow = () => import('../../pages/point/Show.vue');

export default {
    name: 'point.show',
    path: ':point',
    component: PointShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Point',
    },
};
