const RuleEdit = () => import('../../pages/rule/Edit.vue');

export default {
    name: 'rule.edit',
    path: ':rule/edit',
    component: RuleEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Rule',
    },
};
