const BookMagazineIndex = () => import('../../pages/bookMagazine/Index.vue');

export default {
    name: 'bookMagazine.index',
    path: '',
    component: BookMagazineIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Book Magazines',
    },
};
