const NotificationUserShow = () => import('../../pages/notificationUser/Show.vue');

export default {
    name: 'notificationUser.show',
    path: ':notificationUser',
    component: NotificationUserShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Notification User',
    },
};
