import routeImporter from '@enso-ui/ui/src/modules/importers/routeImporter';

const routes = routeImporter(require.context('./weather', false, /.*\.js$/));
const RouterView = () => import('@enso-ui/ui/src/bulma/pages/Router.vue');

export default {
    path: '/weather',
    component: RouterView,
    meta: {
        breadcrumb: 'weather',
        route: 'weather.index',
    },
    children: routes,
};
