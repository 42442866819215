const NotificationUserCreate = () => import('../../pages/notificationUser/Create.vue');

export default {
    name: 'notificationUser.create',
    path: 'create',
    component: NotificationUserCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Notification User',
    },
};
