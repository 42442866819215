const BookMagazineCreate = () => import('../../pages/bookMagazine/Create.vue');

export default {
    name: 'bookMagazine.create',
    path: 'create',
    component: BookMagazineCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Book Magazine',
    },
};
