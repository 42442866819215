const privacy = () => import('../../pages/auth/Privacy.vue');

export default {
    name: 'auth.index',
    path: '',
    component: privacy(),
    meta: {
        breadcrumb: 'index',
        title: 'Auth',
    },
};
