const PostEdit = () => import('../../pages/posts/Edit.vue');

export default {
    name: 'posts.edit',
    path: ':post/edit',
    component: PostEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Post',
    },
};
