const ExtractIndex = () => import('../../pages/extract/Index.vue');

export default {
    name: 'extract.index',
    path: '',
    component: ExtractIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Extracts',
    },
};
