const BadgeUserShow = () => import('../../pages/badgeUser/Show.vue');

export default {
    name: 'badgeUser.show',
    path: ':badgeUser',
    component: BadgeUserShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Badge User',
    },
};
