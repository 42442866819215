const MeritUserShow = () => import('../../pages/meritUser/Show.vue');

export default {
    name: 'meritUser.show',
    path: ':meritUser',
    component: MeritUserShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Merit User',
    },
};
