const LiquidationEdit = () => import('../../pages/liquidation/Edit.vue');

export default {
    name: 'liquidation.edit',
    path: ':liquidation/edit',
    component: LiquidationEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Liquidation',
    },
};
