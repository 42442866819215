const ScoreIndex = () => import('../../pages/score/Index.vue');

export default {
    name: 'score.index',
    path: '',
    component: ScoreIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Scores',
    },
};
