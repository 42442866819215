const BookMagazineImagesIndex = () => import('../../pages/bookMagazineImages/Index.vue');

export default {
    name: 'bookMagazineImages.index',
    path: '',
    component: BookMagazineImagesIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Book Magazine Images',
    },
};
