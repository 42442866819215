const RedemptionShow = () => import('../../pages/redemption/Show.vue');

export default {
    name: 'redemption.show',
    path: ':redemption',
    component: RedemptionShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Redemption',
    },
};
