const PeriodShow = () => import('../../pages/period/Show.vue');

export default {
    name: 'period.show',
    path: ':period',
    component: PeriodShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Period',
    },
};
