const BadgeUserEdit = () => import('../../pages/badgeUser/Edit.vue');

export default {
    name: 'badgeUser.edit',
    path: ':badgeUser/edit',
    component: BadgeUserEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Badge User',
    },
};
