const WeatherIndex = () => import('../../pages/weather/Index.vue');

export default {
    name: 'weather.index',
    path: '',
    component: WeatherIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Weather',
    },
};
