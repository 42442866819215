const PeriodCreate = () => import('../../pages/period/Create.vue');

export default {
    name: 'period.create',
    path: 'create',
    component: PeriodCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Period',
    },
};
