const BookMagazineImagesShow = () => import('../../pages/bookMagazineImages/Show.vue');

export default {
    name: 'bookMagazineImages.show',
    path: ':bookMagazineImages',
    component: BookMagazineImagesShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Book Magazine Images',
    },
};
