const BirthdayShow = () => import('../../pages/birthday/Show.vue');

export default {
    name: 'birthday.show',
    path: ':birthday',
    component: BirthdayShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Birthday',
    },
};
