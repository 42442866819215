const MeritUserIndex = () => import('../../pages/meritUser/Index.vue');

export default {
    name: 'meritUser.index',
    path: '',
    component: MeritUserIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Merit Users',
    },
};
