const RuleShow = () => import('../../pages/rule/Show.vue');

export default {
    name: 'rule.show',
    path: ':rule',
    component: RuleShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Rule',
    },
};
