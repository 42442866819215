const BannerImagesIndex = () => import('../../pages/bannerImages/Index.vue');

export default {
    name: 'bannerImages.index',
    path: '',
    component: BannerImagesIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Banner Images',
    },
};
