const ScoreEdit = () => import('../../pages/score/Edit.vue');

export default {
    name: 'score.edit',
    path: ':score/edit',
    component: ScoreEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Score',
    },
};
