const RuleCreate = () => import('../../pages/rule/Create.vue');

export default {
    name: 'rule.create',
    path: 'create',
    component: RuleCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Rule',
    },
};
