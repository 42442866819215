const BannerEdit = () => import('../../pages/banner/Edit.vue');

export default {
    name: 'banner.edit',
    path: ':banner/edit',
    component: BannerEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Banner',
    },
};
