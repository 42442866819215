const UserIndex = () => import('../../pages/map/UserIndex.vue');

export default {
    name: 'map.userIndex',
    path: 'user',
    component: UserIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Local guide',
    },
};
