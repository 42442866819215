const BookMagazineShow = () => import('../../pages/bookMagazine/Show.vue');

export default {
    name: 'bookMagazine.show',
    path: ':bookMagazine',
    component: BookMagazineShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Book Magazine',
    },
};
