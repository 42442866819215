const BannerImagesCreate = () => import('../../pages/bannerImages/Create.vue');

export default {
    name: 'bannerImages.create',
    path: 'create',
    component: BannerImagesCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Banner Images',
    },
};
