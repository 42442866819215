const ProgramsIndex = () => import('../../pages/programs/Index.vue');

export default {
    name: 'programs.index',
    path: '',
    component: ProgramsIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Programs',
    },
};
