const WeatherShow = () => import('../../pages/weather/Show.vue');

export default {
    name: 'weather.show',
    path: ':weather',
    component: WeatherShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Weather',
    },
};
