const PeriodEdit = () => import('../../pages/period/Edit.vue');

export default {
    name: 'period.edit',
    path: ':period/edit',
    component: PeriodEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Period',
    },
};
