const privacy = () => import('../../pages/auth/EulaEsp.vue');

export default {
    name: 'docs.eulaEsp',
    path: 'eulaEsp',
    component: privacy,
    meta: {
        breadcrumb: 'eulaEsp',
        title: 'eulaEsp',
    },
};
