const ProgramsEdit = () => import('../../pages/programs/Edit.vue');

export default {
    name: 'programs.edit',
    path: ':programs/edit',
    component: ProgramsEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Programs',
    },
};
