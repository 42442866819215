const Details = () => import('../../pages/news/Details.vue');

export default {
    name: 'news.details',
    path: 'details/:news',
    component: Details,
    meta: {
        breadcrumb: 'index',
        title: 'News',
    },
};
