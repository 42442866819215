const RedemptionCreate = () => import('../../pages/redemption/Create.vue');

export default {
    name: 'redemption.create',
    path: 'create',
    component: RedemptionCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Redemption',
    },
};
