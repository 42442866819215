const PointEdit = () => import('../../pages/point/Edit.vue');

export default {
    name: 'point.edit',
    path: ':point/edit',
    component: PointEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Point',
    },
};
