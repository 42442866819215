const MapIndex = () => import('../../pages/map/Index.vue');

export default {
    name: 'map.index',
    path: '',
    component: MapIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Maps',
    },
};
