const VariableIndex = () => import('../../pages/variable/Index.vue');

export default {
    name: 'variable.index',
    path: '',
    component: VariableIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Variables',
    },
};
