const LiquidationShow = () => import('../../pages/liquidation/Show.vue');

export default {
    name: 'liquidation.show',
    path: ':liquidation',
    component: LiquidationShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Liquidation',
    },
};
