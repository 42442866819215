const AlertsIndex = () => import('../../pages/alerts/Index.vue');

export default {
    name: 'alerts.index',
    path: '',
    component: AlertsIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Alerts',
    },
};
