const PostIndex = () => import('../../pages/posts/Index.vue');

export default {
    name: 'posts.index',
    path: '',
    component: PostIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Posts',
    },
};
