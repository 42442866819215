const RuleClone = () => import('../../pages/rule/Clone.vue');

export default {
    name: 'rule.clone',
    path: ':rule/clone',
    component: RuleClone,
    meta: {
        breadcrumb: 'clone',
        title: 'Clone Rule',
    },
};
