const BannerImagesEdit = () => import('../../pages/bannerImages/Edit.vue');

export default {
    name: 'bannerImages.edit',
    path: ':bannerImages/edit',
    component: BannerImagesEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Banner Images',
    },
};
