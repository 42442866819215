const MeritShow = () => import('../../pages/merit/Show.vue');

export default {
    name: 'merit.show',
    path: ':merit',
    component: MeritShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Merit',
    },
};
