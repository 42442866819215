const MeritUserEdit = () => import('../../pages/meritUser/Edit.vue');

export default {
    name: 'meritUser.edit',
    path: ':meritUser/edit',
    component: MeritUserEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Merit User',
    },
};
