const UserNewsIndex = () => import('../../pages/score/UserIndex.vue');

export default {
    name: 'score.userIndex',
    path: 'user',
    component: UserNewsIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Score',
    },
};
