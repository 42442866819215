const VariableEdit = () => import('../../pages/variable/Edit.vue');

export default {
    name: 'variable.edit',
    path: ':variable/edit',
    component: VariableEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Variable',
    },
};
