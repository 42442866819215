const ProgramsCreate = () => import('../../pages/programs/Create.vue');

export default {
    name: 'programs.create',
    path: 'create',
    component: ProgramsCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Programs',
    },
};
