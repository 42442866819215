const MeritIndex = () => import('../../pages/merit/Index.vue');

export default {
    name: 'merit.index',
    path: '',
    component: MeritIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Merits',
    },
};
