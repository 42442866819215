const MeritEdit = () => import('../../pages/merit/Edit.vue');

export default {
    name: 'merit.edit',
    path: ':merit/edit',
    component: MeritEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Merit',
    },
};
