const register = () => import('../pages/auth/Register.vue');

export default {
    name: 'auth.register',
    path: '/register',
    component: register,
    meta: {
        guestGuard: true,
        title: 'register',
        noRedirect: false,
    },
};
