const BadgeUserIndex = () => import('../../pages/badgeUser/Index.vue');

export default {
    name: 'badgeUser.index',
    path: '',
    component: BadgeUserIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Badge Users',
    },
};
