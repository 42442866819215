const UserIndex = () => import('@pages/badgeUser/UserIndex.vue');

export default {
    name: 'badgeUser.userIndex',
    path: 'user',
    component: UserIndex,
    meta: {
        breadcrumb: 'index',
        title: 'badges',
    },
};
