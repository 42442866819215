const UserNewsIndex = () => import('../../pages/news/UserIndex.vue');

export default {
    name: 'news.userIndex',
    path: 'user',
    component: UserNewsIndex,
    meta: {
        breadcrumb: 'index',
        title: 'News',
    },
};
