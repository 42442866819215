const BirthdayEdit = () => import('../../pages/birthday/Edit.vue');

export default {
    name: 'birthday.edit',
    path: ':birthday/edit',
    component: BirthdayEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Birthday',
    },
};
