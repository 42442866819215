const PeriodIndex = () => import('../../pages/period/Index.vue');

export default {
    name: 'period.index',
    path: '',
    component: PeriodIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Periods',
    },
};
