const RuleIndex = () => import('../../pages/rule/Index.vue');

export default {
    name: 'rule.index',
    path: '',
    component: RuleIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Rules',
    },
};
