const CalendarIndex = () => import('@pages/calendar/Index.vue');

export default {
    name: 'calendar.user',
    path: '/calendar/user',
    component: CalendarIndex,
    meta: {
        breadcrumb: 'user',
        title: 'Calendar',
    },
};
