const PostShow = () => import('../../pages/posts/Show.vue');

export default {
    name: 'posts.show',
    path: ':post',
    component: PostShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Post',
    },
};
