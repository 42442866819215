const BadgeUserCreate = () => import('../../pages/badgeUser/Create.vue');

export default {
    name: 'badgeUser.create',
    path: 'create',
    component: BadgeUserCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Badge User',
    },
};
