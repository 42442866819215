const NotificationUserIndex = () => import('../../pages/notificationUser/Index.vue');

export default {
    name: 'notificationUser.index',
    path: '',
    component: NotificationUserIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Notification Users',
    },
};
