const privacy = () => import('../../pages/auth/Eula.vue');

export default {
    name: 'docs.eulau',
    path: 'eulau',
    component: privacy,
    meta: {
        breadcrumb: 'eula',
        title: 'eula',
    },
};
