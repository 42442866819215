const NotificationUserEdit = () => import('../../pages/notificationUser/Edit.vue');

export default {
    name: 'notificationUser.edit',
    path: ':notificationUser/edit',
    component: NotificationUserEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Notification User',
    },
};
