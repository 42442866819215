const UserBookMagazineIndex = () => import('../../pages/bookMagazine/ListMagazine.vue');

export default {
    name: 'bookMagazine.listMagazine',
    path: 'list',
    component: UserBookMagazineIndex,
    meta: {
        breadcrumb: 'list',
        title: 'BookMagazine',
    },
};
