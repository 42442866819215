const BadgeCreate = () => import('../../pages/badge/Create.vue');

export default {
    name: 'badge.create',
    path: 'create',
    component: BadgeCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Badge',
    },
};
