const RedemptionIndex = () => import('../../pages/redemption/Index.vue');

export default {
    name: 'redemption.index',
    path: '',
    component: RedemptionIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Redemptions',
    },
};
