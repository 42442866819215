const PointIndex = () => import('../../pages/point/Index.vue');

export default {
    name: 'point.index',
    path: '',
    component: PointIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Points',
    },
};
