const BadgeIndex = () => import('../../pages/badge/Index.vue');

export default {
    name: 'badge.index',
    path: '',
    component: BadgeIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Badges',
    },
};
