<template>
    <modal v-if="isOpen" @close="closeModal">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ i18n(modalTitle) }}
                </p>
            </header>
            <section class="modal-card-body">
                <slot/>
            </section>
            <footer class="modal-card-foot">
                <button v-if="saveButton" class="button is-primary" @click="save">
                    {{ i18n('Save') }}
                </button>
                <button v-if="cancelButton" class="button" @click="closeModal">
                    {{ i18n('Cancel') }}
                </button>
            </footer>
        </div>
    </modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { Modal } from '@enso-ui/modal/bulma';

export default {
    name: 'ModalComponent',
    components: {
        Modal,
    },
    inject: ['i18n'],
    props: {
        initialTitle: {
            type: String,
            required: true,
        },
        saveButton: {
            type: Boolean,
            default: false,
        },
        cancelButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            modalTitle: '',
        };
    },
    created() {
        this.modalTitle = this.initialTitle;
    },
    methods: {
        openModal() {
            this.isOpen = true;
        },
        closeModal() {
            this.isOpen = false;
        },
        save() {
            // Here we can implement the logic to save the data
            this.closeModal();
        },
    },
};
</script>
<style>
@media screen and (max-width: 768px) {
    .modal-card {
        width: 90%;
    }
}
</style>
