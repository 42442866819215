const VariableShow = () => import('../../pages/variable/Show.vue');

export default {
    name: 'variable.show',
    path: ':variable',
    component: VariableShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Variable',
    },
};
