const BadgeEdit = () => import('../../pages/badge/Edit.vue');

export default {
    name: 'badge.edit',
    path: ':badge/edit',
    component: BadgeEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Badge',
    },
};
