const PointCreate = () => import('../../pages/point/Create.vue');

export default {
    name: 'point.create',
    path: 'create',
    component: PointCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Point',
    },
};
