const NewsImagesShow = () => import('../../pages/newsImages/Show.vue');

export default {
    name: 'newsImages.show',
    path: ':newsImages',
    component: NewsImagesShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show News Images',
    },
};
