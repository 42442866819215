const ExtractShow = () => import('../../pages/extract/Show.vue');

export default {
    name: 'extract.show',
    path: ':extract',
    component: ExtractShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Extract',
    },
};
