import routeImporter from '@enso-ui/ui/src/modules/importers/routeImporter';

const routes = routeImporter(require.context('./point', false, /.*\.js$/));
const RouterView = () => import('@enso-ui/ui/src/bulma/pages/Router.vue');

export default {
    path: '/point',
    component: RouterView,
    meta: {
        breadcrumb: 'point',
        route: 'point.index',
    },
    children: routes,
};
