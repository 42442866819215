const ScoreShow = () => import('../../pages/score/Show.vue');

export default {
    name: 'score.show',
    path: ':score',
    component: ScoreShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Score',
    },
};
