const ProgramsShow = () => import('../../pages/programs/Show.vue');

export default {
    name: 'programs.show',
    path: ':programs',
    component: ProgramsShow,
    meta: {
        breadcrumb: 'show',
        title: 'Show Programs',
    },
};
